import { Link } from "react-router-dom"
import Image from '../images/home_baner.png'
import './MainHEader.css'


function MainHeader() {
  return (
    <header id="main__header">
      <br/>
      <div className="text-center">
      <h2> Shirin Human Rights Watch (SHRW) monitors and documents <br/> violation of women’s fundamental human rights in Afghanistan</h2>
      {/* <h5>A non-governmental, apolitical, and not-for-profit organization that monitor and<br/>
       register human rights violations, especially violation of women's rights, in Afghanistan</h5> */}
      </div>

     

    </header>
  )
}

export default MainHeader