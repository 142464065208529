import './about.css'
import shirin_photo  from '../../images/shirin_photo.jpg'
import about_shirin  from '../../images/about_shirin.jpg'
import shirin_tandis  from '../../images/shirin_tandis.jpg'
function About() {
  return (
            <div className='about_contant'>
              <h2 className='text-center'>ABOUT Shirin Human Rights Watch (SHRW)</h2>
              <hr/>
              <div className='row'>
                
                  <p className="about_p" >Shirin Human Rights Watch (SHRW) is a non-governmental, apolitical, and not-for-profit 
                    organization that monitors and registers human rights violations, especially violation 
                    of women’s rights, in Afghanistan. Our principle mission is to systematically monitor 
                    and document mis-treatment of women and violation of their fundamental rights in 
                    Afghanistan. We also advocate for women’s rights through talking to them about events
                     that impacts their lives, writing about them, and amplifying their voices.
                  </p>
                  <p className="about_p" >
                  Shirin Human Rights Watch is named after Shirin, a legendary girl and symbol of resistance,
                   to tribute to the historical suffering as 
                  well as bravery of women in Afghanistan. A century ago, Abdur Rahman, the then ruler in Kabul supported<a className="about_link" target='_blank' href="https://www.tehrantimes.com/news/470119/Afghan-epic-Chehel-Dokhtar-Mountain-immigration-tale-Coffin"> 
                  politically and militarily by
                    Britain</a>, sent an army to take control of Uruzgan region in central Afghanistan. The local residents in defense of their homeland fought against the army. The army brutally killed hundreds of local people
                     as part of a larger military campaign against Hazara people in Hazarajat region that took lives of <a className="about_link"target='_blank' href='https://repository.law.umich.edu/cgi/viewcontent.cgi?article=2142&context=mjil'> thousands of local Hazara people </a>, 
                     amounting to massacre of 
                    <a className="about_link"target='_blank' href='https://repository.law.umich.edu/cgi/viewcontent.cgi?article=2142&context=mjil'> 60 percent </a> 
                    of Hazara population. Abdur Rahman, an ethnic Pashtun, also launched similar
                     military campaigns in some Pashtun dominated regions to establish a strong central 
                     government, inflicting hundreds of deaths on Pashtuns as well.
                  </p>
                  <p className="about_p" >
                  As men were struggling against the army in Uruzgan, a girl named Shirin formed a small 
                  group of 47 girls to put up a resistance against the army. After being surrounded, they took 
                  shelter on the Shashpar Mountain. The army sieged the mountain to capture the girls. To preserve
                   their honor and dignity, 
                  they ultimately jumped off the top of the mountain. Later the mountain was named after them as the <a className="about_link"target='_blank' href='https://www.hazarainternational.com/2010/07/23/the-40-hazara-girls-falling-down-them-self-chehil-dokhtaran/'> ‘Chehel Dokhtaran Mountain’ (Forty-Girls Mountain)</a>.
                  </p>
                  <p className="about_p"  >There are also artworks, poems and stories written 
                         as a tribute to those girls. Later, local people built a sculpture of Shirin to remember the girls’ bravery 
                          and tragedy.
                         The sculpture was destroyed by Taliban after their return to power in mid-August 2021.</p>
              </div>
              <div className='row'>
                
              <div className='col-md-6'>
                  <figure className="rounded p-3 bg-white shadow-sm">
                    <img src={ about_shirin } alt="" className="w-100 card-img-top"/>
                    <figcaption className="p-4 card-img-bottom">
                      <h2 className="h5 font-weight-bold mb-2 font-italic">Painting by Hussain Ali Hatef ( <a className="about_link"href="https://www.hazarainternational.com/2010/07/23/the-40-hazara-girls-falling-down-them-self-chehil-dokhtaran/" target='_blank'>www.hazarainternational.com</a>       )</h2>
                     
                      
                      
                  {/* <p className="about_p"  className="mb-0 text-small text-muted font-italic">
                  Source: 
                  </p> */}
                    </figcaption>
                  </figure>
                </div>

                <div className="col-md-6">
                  <figure className="rounded p-3 bg-white shadow-sm">
                    <img src={ shirin_tandis } alt="" className="w-100 card-img-top"/>
                    <figcaption className="p-4 card-img-bottom">
                       <h2 className="h5 font-weight-bold mb-2 font-italic">Shirin statue before and after destruction</h2> {/*mb-0 text-small text-muted font-italic */}
                    </figcaption>
                  </figure>
                </div>
                
              <div/>
              <div className='row'>
              <p className="about_p"  >Shirin and her fellows have become a symbol of honor, 
                resistance, bravery as well as their story narrates the historical cruelty and atrocities
                 women have been suffering from in Afghanistan from far past till present. The Forty Girls’
                  suicide for preserving their dignity is echoed today in each corner of 
                Afghanistan in various forms in the same way as their bravery in defense of their rights.  </p>
              </div>
            </div>
            </div>

  )
}

export default About