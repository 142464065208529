import MainHeader from '../../components/MainHeader'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './home.css'
import $ from 'jquery'; 
import Carousel from 'react-bootstrap/Carousel';
import grmphoto  from '../../images/grm.jpeg';
import genderap  from '../../images/gender_ap.jpeg';
import opinion_photo  from '../../images/opinion_photo.jpeg';
import victim_photo  from '../../images/victim_photo.jpeg';



import { useState , useEffect } from 'react';
import Collapsible from 'react-collapsible';
import {Link , NavLink, useNavigate} from 'react-router-dom'
import News_details from './news_details';

// import news_details from './news_details'



function Home() {

  const navigate = useNavigate();
  const news_readMore=(title)=>{
    navigate('/news_details',{state:{news_title:title}});
  }

 const victim_page=()=>{
  navigate('/victim_memories')
 }

 const grassroot_page=()=>{
  navigate('/grassroots_movement')
 }

 const gender_page=()=>{
  navigate('/gender_apartheid')
 }
 const opinion_page=()=>{
  navigate('/opinion')
 }


// const read_more = (id) =>
// {
//   // alert('hi')
//   $('#'+id).removeAttr('rows')
//   $('#'+id).height($("#"+id).prop('scrollHeight'));
// }


  const [web_news, setWeb_news] = useState([])
  const [gender_ap, setGender_ap] = useState([])
  const [groot_m, setGroot_m] = useState([])
  const [victim_m, setVictim_m] = useState([])
  const [opinion_data, setOpinion_data] = useState([])


  useEffect(() => {
  fetch('/backend/web_news')
    .then(response => {
        return response.json()
      })
      .then(data => {
        setWeb_news(data)
      })


    fetch('/backend/home_gender_ap')
    .then(response => {
        return response.json()
      })
      .then(data => {
        setGender_ap(data)
      })
    
    fetch('/backend/home_grassr_m')
    .then(response => {
        return response.json()
      })
      .then(data => {
        setGroot_m(data)
      })

    fetch('/backend/home_victim_m')
      .then(response => {
          return response.json()
        })
        .then(data => {
          setVictim_m(data)
        })
    
    fetch('/backend/home_opinion')
    .then(response => {
        return response.json()
      })
      .then(data => {
        setOpinion_data(data)
      })





    },[])



  

  


  const [location, setLocation] = useState('');
  const [datetime, setDatetime] = useState('');
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState('');
  const [reporter, setReporter] = useState('');

  const handleSubmit = (data) => {
    data.preventDefault();
    fetch('/backend/user_violation',{
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        location:location,
        datetime:datetime,
        description: description,
        attachment: attachment,
        reporter:reporter
      })
    }).then((response) => response.json())
      .then((data) => {
        if(data['code'] ===200)
        {
          alert(data['message'])
        }
        else
        {
          alert(data['message'])
        }
      })


  }


  return (
    <>
      <MainHeader/>
      <div className='row violation_container'>
      <div className='news'>
      <Card  className="text-center news">
      {/* <Card.Header>Featured</Card.Header> */}
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title> */}
        {/* <Card.Text> */}
          {/* With supporting text below as a natural lead-in to additional content. */}
          
        {/* </Card.Text> */}
        {/* <Button variant="primary">Go somewhere</Button> */}
        <div className='row'>
       
            {
            web_news.map(vol =>(
              <div className='col-md-4 '>
              <Card className='violation_item'>
                <Card.Body>
                <div className='c_header'>News</div>
                <img src={'https://shrw.org/backend/public/uploads/news/'+vol.attachment} style={{ height:'100%', width:'100%' }} alt="news photo"/>
                <Card.Header className='fw-bold card_header '>{vol.title}</Card.Header>
                  <Card.Text  >
                    <textarea spellCheck="false" id={vol.id} className='form-control news_description' rows={6}>
                      {vol.description}
                    </textarea> 
                  </Card.Text>
                  {/* <Button variant="outline-primary">Primary</Button>{' '} */}
                  <Button className='read_more' variant="outline-primary" size="sm"><Link to={'/news_details?news_title='+vol.title }>read more...</Link> </Button>
                </Card.Body>
              </Card>
            </div> 
              ))}
            
         
        </div>
      </Card.Body>
    </Card>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

      <Card className='u_violation'>
          <Card.Body>
              <div className='row'>
              <Carousel data-bs-theme="">
                <Carousel.Item>
                  <div className='slide_show1'>
                  {
                    gender_ap.map(vol =>(
                    <div className='row'>
                      <div className='col-md-4'>
                        <img
                          className="d-block w-100 rounded"
                          src={'https://shrw.org/backend/public/uploads/gender_ap/'+vol.attachment} 
                          alt="First slide"
                        />
                      </div>
                      <div className='col-md-8'>
                          <h5 className='data_title'>{vol.title}</h5>
                          <textarea readOnly spellCheck="false" id={vol.id} className='form-control data_description' rows={8}>
                            {vol.description}</textarea>
                            <Carousel.Caption> Read More... </Carousel.Caption>
                      </div>
                    </div>
                  ))}
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='slide_show1'>
                  {
                    groot_m.map(vol =>(
                    <div className='row'>
                      <div className='col-md-4'>
                        <img
                          className="d-block w-100 rounded"
                          src={'https://shrw.org/backend/public/uploads/grassroot_m/'+vol.attachment} 
                          alt="First slide"
                        />
                      </div>
                      <div className='col-md-8'>
                          <h5 className='data_title'>{vol.title}</h5>
                          <textarea readOnly spellCheck="false" id={vol.id} className='form-control data_description' rows={8}>
                            {vol.description}</textarea>
                            <Carousel.Caption> Read More... </Carousel.Caption>
                      </div>
                    </div>
                  ))}
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='slide_show1'>
                  {
                    victim_m.map(vol =>(
                    <div className='row'>
                      <div className='col-md-4'>
                        <img
                          className="d-block w-100 rounded"
                          src={'https://shrw.org/backend/public/uploads/victim_memory/'+vol.attachment} 
                          alt="First slide"
                        />
                      </div>
                      <div className='col-md-8'>
                          <h5 className='data_title'>{vol.title}</h5>
                          <textarea readOnly spellCheck="false" id={vol.id} className='form-control data_description' rows={8}>
                            {vol.description}</textarea>
                            <Carousel.Caption> Read More... </Carousel.Caption>
                      </div>
                    </div>
                  ))}
                  </div>
                </Carousel.Item>


                <Carousel.Item>
                  <div className='slide_show1'>
                  {
                    opinion_data.map(vol =>(
                    <div className='row'>
                      <div className='col-md-4'>
                        <img
                          className="d-block w-100 rounded"
                          src={'https://shrw.org/backend/public/uploads/opinion/'+vol.attachment} 
                          alt="First slide"
                        />
                      </div>
                      <div className='col-md-8'>
                          <h5 className='data_title'>{vol.title}</h5>
                          <textarea readOnly spellCheck="false" id={vol.id} className='form-control data_description' rows={8}>
                            {vol.description}</textarea>
                            <Carousel.Caption> Read More... </Carousel.Caption>
                      </div>
                    </div>
                  ))}
                  </div>
                </Carousel.Item>


                
              </Carousel>
              </div>
          </Card.Body>
      </Card>

      <div className='news'>
      <Card  className="text-center news">
      {/* <Card.Header>Featured</Card.Header> */}
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title> */}
        {/* <Card.Text> */}
          {/* With supporting text below as a natural lead-in to additional content. */}
          
        {/* </Card.Text> */}
        {/* <Button variant="primary">Go somewhere</Button> */}
        <div className='row'>
            <div className='col-md-6 '>
              <Card className=''>
                <Card.Body>
                  <div className='c_header'><a href='#' onClick={()=>{gender_page()}}>Gender Apartheid</a></div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <img src={ genderap } style={{ height:'100%', width:'100%' }} alt="news photo"/>
                    </div>
                    <div className='col-md-6 all_text'>
                      <Card.Text  >
                        <p   className='form-control all_section' >
                        From mid-August 2021 the women and girls in Afghanistan have been fighting an unprecedented nationwide gender-based discrimination, a discrimination that amounts to gender apartheid in accord with the definition of apartheid in international law. We track and document Taliban’s mis-treatment of women and advocate for women’s rights.
                        </p> 
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> 

            <div className='col-md-6 '>
              <Card className=''>
                <Card.Body>
                  <div className='c_header'><a href='#' onClick={()=>{grassroot_page()}}>Grassroots Movements</a></div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <img src={grmphoto} style={{ height:'100%', width:'100%' }} alt="news photo"/>
                    </div>
                    <div className='col-md-6 all_text'>
                      <Card.Text  >
                        <p   className='form-control all_section' >
                        Following the fall of Kabul to the Taliban and the increasing restrictions on women’s rights, women formed several movements in the country. They have been actively fighting for their rights in several fronts: organizing protests inside and outside the country, organizing social media campaigns, and raising their voice in international platforms.
                        </p> 
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          <br/>
          <div className='row'>
            <div className='col-md-6 '>
              <Card className=''>
                <Card.Body>
                  <div className='c_header'><a href='#' onClick={()=>{victim_page()}}>Victims’ Memories</a> </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <img src={victim_photo} style={{ height:'100%', width:'100%' }} alt="news photo"/>
                    </div>
                    <div className='col-md-6 all_text'>
                      <Card.Text  >
                        <p   className='form-control all_section' >
                        Since the return of the Taliban to power, women in Afghanistan have been paying a high price for defending their rights. Those involved in civil activism subjected to severe mis-treatments. To date, many have been murdered, imprisoned, tortured and intimated. We echo the voice of these activists via talking to them and writing about them.
                        </p> 
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> 

            <div className='col-md-6 '>
              <Card className=''>
                <Card.Body>
                  <div className='c_header'> <a href='#' onClick={()=>{opinion_page()}}> Opinion</a> </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <img src={opinion_photo} style={{ height:'100%', width:'100%' }} alt="news photo"/>
                    </div>
                    <div className='col-md-6 all_text'>
                      <Card.Text  >
                        <p   className='form-control all_section' >
                        Experts, journalists and researchers, especially female authors, write informative policy-oriented articles about the violation of women’s rights by the Taliban, its impacts on the country’s future, the international community’s moral obligations towards women, the regional countries’ stance, and the legal aspect of gender apartheid in Afghanistan.
                        </p> 
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> 


        </div>
      </Card.Body>
   
    <div className='row user_report'>
    <div className='col-md-4 report_text'><br/>
                        <h5 className='text-warning'>Report a Violation</h5>
                        
                        <br/>
                        <p className='report_violation'>
                          If you witness a violation of women’s rights or have evidence of such violations 
                          (rape, torture, detention, forced marriage or denial of access to services) please report to us
                           via filling out the form below. Please correctly select the violation place and time, write a 
                           precise description and upload the evidence. Your message will be sent anonymously.
                        </p>
                        <p className='report_violation'> * You can also write your name and contact details. Your data will remain confidential </p>
                      </div>

              <div className='col-md-8'>
              <br/>
              <form className='user_data' onSubmit={handleSubmit}>
                    <div className='row'>
                      
                      <div className='col-md-6'>
                        {/* <label className=''>Location</label> */}
                        <input placeholder='Location' onChange={(event) => setLocation(event.target.value)} type="text" className='form-control'/>
                      </div>
                      <div className='col-md-6'>
                        <input placeholder='Date and Time' onChange={(event) => setDatetime(event.target.value)} type="date" className='form-control'/>
                      </div>
                    </div>
                    <div className='row'>
                        <div>
                        <textarea placeholder='Description' onChange={(event) => setDescription(event.target.value)} rows="3" className='form-control'></textarea>
                    </div>
                    </div>
                    <div className='row'>
                      <div>
                      <input onChange={(event) => setAttachment(event.target.value)} type="file" className='form-control'/>
                      </div>
                    </div>
                  
                      <div className='row'>
                       <div>
                        <textarea rows="2" placeholder='Name and Contact Details' onChange={(event) => setReporter(event.target.value)} className='form-control'></textarea>
                      </div>
                      </div>
                       <Button className='sm m-1'  type="submit" variant="primary">Submit</Button>
              </form>
              </div>
              </div>
              </Card>
    </div>

    </div>
    </>
    
  )
}


export default Home
