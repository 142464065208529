import Card from 'react-bootstrap/Card';
import {Facebook}from 'react-bootstrap-icons';
import {Linkedin}from 'react-bootstrap-icons';
import {Twitter}from 'react-bootstrap-icons';
import {Instagram}from 'react-bootstrap-icons';
import {Link, NavLink} from 'react-router-dom';
import './Footer.css'


function Footer()
{
    return (
        <Card className="text-center footer">
            <footer className="py-5">
                <div className="row">
                <div className="col-6 col-md-2 mb-3">
                    <ul className="nav flex-column">
                    <li className="nav-item mb-2">  <NavLink to="/"             className="nav-link p-0 ">      Home        </NavLink>  </li>
                    <li className="nav-item mb-2">  <NavLink to="/violation"    className="nav-link p-0 ">      Violation   </NavLink>  </li>
                    <li className="nav-item mb-2">  <NavLink to="/reports"      className="nav-link p-0 ">      Reports     </NavLink>  </li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 mb-3">
                   
                    <ul className="nav flex-column">
                    <li className="nav-item mb-2">  <NavLink to="/gender_apartheid"     className="nav-link p-0 ">      Gender Apartheid     </NavLink>  </li>
                    <li className="nav-item mb-2">  <NavLink to="/grassroots_movement"  className="nav-link p-0 ">      Grassroots Movements </NavLink>  </li>
                    <li className="nav-item mb-2">  <NavLink to="/victim_memories"      className="nav-link p-0 ">      Victim's Memories    </NavLink>  </li>
                    
                    </ul>
                </div>

                <div className="col-6 col-md-2 mb-3">
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"> <NavLink to="/about"     className="nav-link p-0 ">              About Us    </NavLink>       </li>
                        <li className="nav-item mb-2"> <NavLink to="/contact"   className="nav-link p-0 ">              Contact     </NavLink>       </li>
                        <li className="nav-item mb-2"> <a  href="admin/index.html" className="nav-link p-0 ">   ..       </a>       </li>
                    </ul>
                </div>
                <div className="col-md-5 offset-md-1 mb-3">
                    <form>
                    <div className="gap-2 p-2">
                        <h5>Subscribe for our new events and more...</h5>
                        <label  className="visually-hidden">Email address</label>
                        <input id="newsletter1" type="text" className="form-control" placeholder="Email address"/>
                        <br/>
                        <input  className=" w-100 btn btn-primary  form-control"  type="submit" value="Subscribe"/>
                    </div>
                  

                        
                    
                    </form>
                </div>
                </div>

                <div className="p-4 d-flex flex-column flex-sm-row justify-content-between  border-top footer_media">
                <p>Copyright ©2023 All rights reserved.</p>
                <ul  className="list-unstyled d-flex ">
                    <li className="ms-3"><a className="link-dark" target='_blank' href="https://www.facebook.com/profile.php?id=100095342251449"><Facebook color="royalblue" size={40} /></a></li>
                    <li className="ms-3"><a className="link-dark" target='_blank' href="https://twitter.com/ShirinHrw"><Twitter color="royalblue" size={40} /></a></li>
                    <li className="ms-3"><a className="link-dark" target="_blank" href="https://www.instagram.com/shirin.hrw/"><Instagram color="royalblue" size={40} /></a></li>
                    <li className="ms-3"><a className="link-dark" target='_blank' href="#"><Linkedin color="royalblue" size={40}/></a></li>
                    
                    
                    
                </ul>
                </div>
            </footer>
        </Card>
    )
}

export default Footer