import {HashRouter ,BrowserRouter  , Routes , Route}  from 'react-router-dom'

import Home from './pages/home/Home'
import News_details from './pages/home/news_details'
import Single_web_news from './pages/home/single_web_news'
import React from 'react';
import Gender_apartheid_details from './pages/gender_apartheid/gender_apartheid_details'
import Grassroots_movement_details from './pages/grassroots_movments/grassroots_movments_details'
import Victim_memories_details from './pages/victim_memories/victim_memories_details'
import Opinions_details from './pages/opinion/opinion_details'


import About from './pages/about/About'
import Contact from './pages/contact/Contact'
// import Gallery from './pages/gallery/Gallery'
import Reports from './pages/reports/Reports'
import Violation from './pages/violation/Violation'
import NotFound from './pages/notFound/NotFound'
import Gender_apartheid from './pages/gender_apartheid/gender_apartheid'
import Grassroots_movment from './pages/grassroots_movments/grassroots_movments'
import Victim_memory from './pages/victim_memories/victim_memories'
import Opinion from './pages/opinion/opinion'

import Navbar from './components/Navbar'
import Footer from './components/Footer'







function App() {
  return (
    <BrowserRouter>
      <React.Fragment>
      <Navbar/>
      <Routes>
        
        <Route index element={<Home/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='news_details' element={<News_details/>}/>
        <Route path='Gender_apartheid_details' element={<Gender_apartheid_details/>}/>
        <Route path='Grassroots_movement_details' element={<Grassroots_movement_details/>}/>
        <Route path='Grassroots_movement_details' element={<Grassroots_movement_details/>}/>
        <Route path='Victim_memories_details' element={<Victim_memories_details/>}/>
        <Route path='Opinions_details' element={<Opinions_details/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='reports' element={<Reports/>}/>
        <Route path='violation' element={<Violation/>}/>
        <Route path='gender_apartheid' element={<Gender_apartheid/>}/>
        <Route path='grassroots_movement' element={<Grassroots_movment/>}/>
        <Route path='opinion' element={<Opinion/> }/>
        <Route path='victim_memories' element={<Victim_memory/>}/>
        <Route path='*' element={<NotFound/>}/>
        <Route path='single_web_news' element={<Single_web_news/>}/>
      </Routes>
      <Footer/>
      </React.Fragment>
    </BrowserRouter>
  )
}

export default App