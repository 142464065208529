import $ from 'jquery'; 
import Card from 'react-bootstrap/Card';
import { useState , useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom/client';
function Gender_apartheid_details()
{
    const show_gender_ap_details = (n_id) =>
  {
    const new_news = document.getElementById('gender_ap_content');
    const news_result = ReactDOM.createRoot(new_news);
    news_result.render(
        gender_ap_left.map(val => (
          val.id===n_id ? (
          <>
            <p className='title'><strong>{val.title}</strong></p>
            <p className='time'>{val.date_time}</p>
            <img className='news_photo img-thumbnail' src={'https://shrw.org/backend/public/uploads/grassroot_m/'+val.attachment}  alt="news photo"/>
            <p className='author'>By &nbsp; {val.author}</p>
            <div  spellCheck="false" className='news_description '>{val.description}</div >
          </>
          ): null
          )
    ))
  }
    const location = useLocation();
    const [gender_ap_top, setGender_ap_top] = useState([])
    const [gender_ap_left, setGender_ap_left] = useState([])

    useEffect(() => 
    {
        fetch('/backend/grassroots_movment_top',
        {
          method: 'POST',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
          id:location.state.movement_id
          })
        }).then(response => { return response.json()})
          .then(data => {setGender_ap_top(data) })

        fetch('/backend/grassroots_movment_left')
            .then(response => { return response.json() })
            .then(data => { setGender_ap_left(data) }) 
    },[])   
    return(
        <>
            <Card>
                <div className="row page_content">
                    <div className="col-md-8">
                        <div id="gender_ap_content">
                            { gender_ap_top.map(vol => (
                                <>      
                                <p className='title'><strong>{vol.title}</strong></p>
                                <p className='time'>{vol.date_time}</p>
                                <img className='news_photo img-thumbnail' src={'https://shrw.org/backend/public/uploads/grassroot_m/'+vol.attachment}  alt="news photo"/>
                                <p className='author'>By &nbsp; {vol.author}</p>
                                <div  spellCheck="false" className='news_description '>{vol.description}</div >
                                </>))
                            }
                        </div>
                    </div>
                    <div className="col-md-4 " > 
                    <br/>
                        { gender_ap_left.map( val=>(
                            <div className='data_left'>
                            <img id="open_news_image" className='news_photo img-thumbnail' src={'https://shrw.org/backend/public/uploads/grassroot_m/'+val.attachment}  alt="news photo"/>
                            <a className='title' id="open_news" onClick={()=>show_gender_ap_details(val.id)}><strong>{val.title}</strong></a>
                            </div>))
                        }
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Gender_apartheid_details;