import './notFound.css'


function NotFound() {
  return (
    <div id="notFound_contant" className='text-center'>
      <br/>
      <br/>
        <h3>Page Not Found</h3>
        <br/>
        <br/>

    </div>
  )
}

export default NotFound