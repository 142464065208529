import Card from 'react-bootstrap/Card';
import {useNavigate} from 'react-router-dom'
import { useState , useEffect } from 'react';
import './victim_memories.css'

function Victim_memories()
{
   
  const navigate = useNavigate();
  const victim_m_details=(id)=>
  {
    navigate('/victim_memories_details',{state:{victim_m_id:id}});
  }
  const [gender_data, setGender_data] = useState([])
  useEffect(() => 
  {
      fetch('/backend/victim_memories_list')
      .then(response => {return response.json()})
      .then(data => { setGender_data(data)}) 
  },[])

 return(
    <>  
      <Card className="text-center">
        <div className='gen_ap'>
        <div className='row'>
          { gender_data.map(vol => (
            <> 
            <div className='col-md-3 data_container'>
              <a href='#' className='g_link' onClick={()=>{victim_m_details(vol.id)}} >
                <img className='gender_ap_photo' src={'https://shrw.org/backend/public/uploads/victim_memory/'+vol.attachment}  alt="news photo"/>
                <div className='gender_title text-center'>
                  <p className='g_date'>{ vol.date_time.substring(0,17)}</p>
                  <p className='g_title'>
                  {vol.title}</p>
                </div>
              </a>
            </div>
            </>))
          }
        </div>
        </div>
      </Card>
    <br/>
  </>
  )
}

export default Victim_memories;