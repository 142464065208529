import './contact.css'
import contact_email from '../../images/email.png'
import contact_photo from '../../images/contact.png'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

import {useState} from 'react'
function Contact() {

  const shareUrl = 'https://shrw.org/#/news_details?news_title=Taliban%20Stopped%20100%20Students%20Flying%20to%20Dubai%20for%20Education'
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email , setEmail]=useState('');
  const [message, setMessage] = useState('');

  
  const handleSubmit = (data) => {
    data.preventDefault();
    fetch('/contact_data',{
      method: 'POST',
      headers: { 'Content-Type':'applicaton/json'},
      body: JSON.stringify({
          name: name,
          lastName:lastName,
          email: email,
          message: message,
      })


    })
    .then((response) => response.json())
    .then((data) => {
      if(data['code'] === 200)
      {
        console.log(data['message'])
      }
      else
      {
        console.log(data['message'])
      }
    })
  }



  return (
    <div>
      <div id="contact_baner" className='row p-3'>
        <h3 className='text-center text_style'> Get in Touch </h3>
        <div>
          <p className='text_style  '>If you are a journalist, a human rights activist, a researcher, a think-tank or a human rights organization interested in our work, or if you are a victim and want to tell your story and have your voice heard, please contact us via filling out the contact form. Your inquiry will be responded in the earliest convenience.</p>
        </div>
     
      <br/>
      <div className='row p-3'>
      <div className='col-md-6'>
        {/* <label>  <img className='rounded-circle border border-success' src={contact_email} alt='contact image'/> <span> contact@shwr.org </span></label> */}
          <img className='form-control rounded' src={contact_photo} alt='photo' />
       
      </div>
        <div className='col-md-6'>
          <form onSubmit={handleSubmit}>            
            <div className="row">
              <div className='col-md-6'>
                <label className='text_style'>Name</label>
                <input onChange={(event) => setName(event.target.value)} required type='text' className='form-control p-2' />
              </div>
              <div className='col-md-6'>
                <label className='text_style' >Last Name</label>
                <input  onChange={(event) => setLastName(event.target.value) } required type='text' className='form-control p-2' />
              </div>
            </div>

            <label className='text_style'>Email</label>
            <input type='email' onChange={ (event)=>setEmail(event.target.value) } className='form-control p-2' />
            
            <label className='text_style'>Write Your Message</label>
            <textarea  onChange={ (event)=>setMessage(event.target.value) } className='form-control' rows="5"  >
            </textarea>
            <br/>
            <button  type="submit" className="form-control btn btn-primary">Submit</button>
          </form>
        </div>
        
      </div>
      </div>
      {/* <div className='row'>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <FacebookShareButton url={shareUrl} title={"this is an example to share in facebook"}>
          Share on Facebook
      </FacebookShareButton>

      </div> */}
      </div>

   
  )
}

export default Contact