import MainHeader from '../../components/MainHeader'
import Button from 'react-bootstrap/Button';
import './home.css'
import $ from 'jquery'; 
import Card from 'react-bootstrap/Card';
import { useState , useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom/client';
import Collapsible from 'react-collapsible';
import Helmet from "react-helmet";
import MetaTags from 'react-meta-tags';
import { HashLink } from 'react-router-hash-link';

function Single_web_news() {
  const location = useLocation();
  const [news_data, setNews_data] = useState([])
  const [left_news_data, setLeft_news_data] = useState([])

  // const {state} = location
  useEffect(() => {
    // const location = useLocation()
    const params = new URLSearchParams(location.search)
    //  const title = location.state.userId
    //  alert(params.get("userId"))


  fetch(`/backend/single_web_news?title=${params.get("news_title")}`).then(response => {
        return response.json()
      })
      .then(data => {
        setNews_data(data)
      })


  fetch('/backend/left_web_news',{
    method: 'POST',
    headers: {'Content-Type':'application/json'},
  }).then(response => {
        return response.json()
      })
      .then(data => {
        setLeft_news_data(data)
      })  
    },[])
    $(function() {
      $('textarea').each(function() {
          $(this).height($(this).prop('scrollHeight'));
      });
  });
  return (
    <>

      <div className='row violation_container'>
        <Card className="text-center">
        
          <br/>
          <br/>
          <div className='row'>
            <div className='col-md-9'>
              <div id="news_content">
               { news_data.map(vol => (
                <>
                  <MetaTags>
                    <Helmet>
                      <title>{vol.title}</title>
                      <meta name="title" key="title" content={vol.title} />
                      <meta property="og:title" key="og:title" content={vol.description} />
                      <meta
                        property="og:image"
                        key="og:image"
                        content={'https://shrw.org/backend/public/uploads/news/'+vol.attachment}
                      />
                    </Helmet>
                  </MetaTags>
                  <p className='title'><strong>{vol.title}</strong></p>
                  <p className='time'>{vol.date_time}</p>
                  <img className='news_photo img-thumbnail' src={'https://shrw.org/backend/public/uploads/news/'+vol.attachment}  alt="news photo"/>
                  <p className='author'>By &nbsp; {vol.author}</p>
                  <div  spellCheck="false" className='news_description '>{vol.description}</div >
                </>))
                }
              </div>
            </div>
            <div className='col-md-3'>
              { left_news_data.map( val=>(
                  <div className='data_left'>
            
                    <img id="open_news_image" className='news_photo img-thumbnail' src={'https://shrw.org/backend/public/uploads/news/'+val.attachment}  alt="news photo"/>
                      <HashLink  className='title' id="open_news" to={'/news_details?news_title='+val.title }> <strong>{val.title}</strong></HashLink >
                    </div>
                ))
              }
            </div>
          </div>
          <br/>
        </Card>
      </div>
      <br/>
    </>
  )
  }

export default Single_web_news

