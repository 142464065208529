import './violation.css'
import React, { useCallback,useState , useEffect } from "react";
import Card from 'react-bootstrap/Card';
import {useCurrentPng} from 'recharts-to-png';
import FileSaver from 'file-saver';
import { BarChart, YAxis,CartesianGrid, Tooltip, Legend, XAxis, Bar} from 'recharts';
// import { Button } from 'bootstrap';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'


function Violation() 
{

  

  const [getPng, { ref, isLoading }] = useCurrentPng();
  const [victim_chart, setVictim_chart] = useState([]);
  const [actor_chart, setActor_chart] = useState([]);
  const [violation_type_chart, setViolation_type_chart] = useState([]);
  const [province, setProvince] = useState([]);
  const [victim_type, setVictim_type] = useState([]);
  const [violation_type_list, setViolation_type_list] = useState([]);
  const [actor_type_list, setActor_type_list] = useState([]);
  const [ province_filter, setProvince_filter ] = useState([])
  const [victim_type_filter, setVictim_type_filter] = useState([]);
  const [violation_type_filter, setViolation_type_filter] = useState([]);
  const [actor_type_filter, setActor_type_filter] = useState([]);
  const [fromDate, setFromDate] = useState([]);
  const [toDate, setToDate] = useState([]);
  
  
  const handle_change_province = (e) =>{
    setProvince_filter(e.value)
  }

  const handle_change_victim = (e)=>
  {
    setVictim_type_filter(e.value)
  }

  const handle_change_violation = (e)=>
  {
    setViolation_type_filter(e.value)
  }

  const handle_change_actor = (e)=>
  {
    setActor_type_filter(e.value)
  }



  const handleSubmit = (data) => {
    const formData = new FormData();
    formData.append("province_filter", province_filter);
    formData.append("victim_filter", victim_type_filter);
    formData.append("violation_filter", violation_type_filter);
    formData.append("actor_filter", actor_type_filter);
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    data.preventDefault();
    

    fetch('/backend/filter_violation',{
      method: 'POST',
      body: formData
    }) 
    .then((response) => response.json())
    .then(data => (
      setViolation_type_chart(data['violation_data']),
      setVictim_chart(data['victim_data']),
      setActor_chart(data['actor_data'])
    ))
  }
  
  const handleReset = ()=>
  {
    window.location.reload();
  }

  const handleDownload = useCallback(async () => 
  {
    const png = await getPng();
    if (png) {FileSaver.saveAs(png, 'myChart.png');}
  },[getPng]);
  
  useEffect(() => 
  {
    fetch('/backend/violation_victim_chart')
      .then(response => { return response.json()})
      .then(data => {setVictim_chart(data)})

    fetch('/backend/actor_type_chart')
      .then(response => { return response.json()})
      .then(data => {setActor_chart(data)})


    fetch('/backend/violation_type_chart')
      .then(response => {return response.json()})
      .then(data => {setViolation_type_chart(data)})

    fetch('/backend/province_list')
    .then(response => {return response.json()})
    .then(data => {setProvince(data)})

    fetch('/backend/victims_list')
    .then(response=> { return response.json()})
    .then(data => {setVictim_type(data)} )

    fetch('/backend/violation_type_list')
    .then(response=> { return response.json()})
    .then(data => {setViolation_type_list(data)} )

    fetch('/backend/actor_list')
    .then(response=> { return response.json()})
    .then(data => {setActor_type_list(data)} )

     



    },[])

    return (
    <>
    <Card className='p-3'><br/>
      <br/>
    

      <form ref={ref} onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-3'>
          Province:
           <Select  onChange={handle_change_province}  
           options={
                    province.map(vol => ({
                      value: vol.id,
                      label: vol.name,
                      }))
           } />
        </div>
        <div className='col-md-3'>
          Victims:
          <Select onChange={handle_change_victim} options={
            victim_type.map(vol=>({
              value : vol.id,
              label : vol.name
            }))
          }

          />
        </div>
        <div className='col-md-3'>
          Violation Type:
          <Select onChange={handle_change_violation} options = 
          {
            violation_type_list.map(vol=>({
              value : vol.id,
              label : vol.name
            }))
          }
          />
        </div>
        <div className='col-md-3'>
          Actor:
           <Select onChange={handle_change_actor} options = 
          {
            actor_type_list.map(vol=>({
              value : vol.id,
              label : vol.name
            }))
          }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          From Date
          <input value={fromDate} onChange={(event)=> setFromDate(event.target.value)}  type="date" className='form-control'/>
        </div>
        <div  className='col-md-3'>
          To Date
          <input value={toDate} onChange={(event)=> setToDate(event.target.value)}  type="date" className='form-control'/>
        </div>
        <div className='col-md-6'>
        <div className='row'>
          <div className='col-md-6'>
            <br/>
            <input  className=" w-100 sm btn btn-primary  form-control"  type="submit" value="Filter"/>
            
          </div>
          <div className='col-md-6'>  
            <br/>
            
            <input  className=" w-100 sm btn  btn-info reset form-control" onClick={handleReset}  type="reset" value="Clear Filter"/>
            
          </div>

        </div>
        </div>
        </div>
      </form>
     
      <br/>
      <br/>
      <div className='row p-3 text-center form-control'>
        <BarChart ref={ref}
          width={1000}
          height={400}
          data={
            violation_type_chart.map(vol => ({
            name: vol.name,
            ViolationType: vol.v_t_counter,
            }))
          }
          margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
          }}
          barSize={10}
        >
          <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="ViolationType" fill="green" background={{ fill: "#eee" }} />
        </BarChart>
        </div>

      <div className='row'>
        <div className='col-md-4 p-4'> 
        
        <BarChart ref={ref}
          width={500}
          height={300}
          data={
            victim_chart.map(vol => ({
            name: vol.name,
            Victims: vol.vc_counter,
            }))
          }
          margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
          }}
          barSize={10}
        >
          <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="Victims" fill="#8884d8" background={{ fill: "#eee" }} />
        </BarChart>
        </div>
        <div className='col-md-8 p-4'>
        <BarChart ref={ref}
          width={800}
          height={300}
          data={
            actor_chart.map(vol => ({
            name: vol.name,
            Actors: vol.v_actor,
            }))
          }
          margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
          }}
          barSize={10}
        >
          <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="Actors" fill="#8884d8" background={{ fill: "#eee" }} />
        </BarChart>
        </div>
      </div>
      <br/>


      
      <div>
      <button onClick={handleDownload}>
        {isLoading ? 'Downloading...' : 'Download Chart'}
      </button> 
      </div>
    
    </Card>
    
  </>
  )
}

export default Violation